@import 'erpcore/assets/scss/main';

.footer {
    $this: &;
    position: relative;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: ($spacing * 3) ($spacing * 6);
    color: rgba(getColor(grey), 1);
    font-size: 15px;
    line-height: 22px;
}
