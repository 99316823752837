@import 'erpcore/assets/scss/main';

.features-list-slider {
    position: relative;
    top: -28px;
    overflow: hidden;

    // arrows & dots container

    &__controls {
        display: flex;
        align-items: center;
        align-content: center;
        width: 100%;
        margin: 0 0 $spacing * 4;
    }

    .carousel {
        // arrows

        &__back-button,
        &__next-button {
            width: $spacing * 3;
            height: $spacing * 3;
            margin: 0;
            padding: 0;
            background: none;
            border: none !important;
            line-height: $spacing * 3;
            cursor: pointer;
            transition: getTransition();
            z-index: 2;

            &:hover {
                background: none;
            }

            &:before {
                display: block;
                width: $spacing * 3;
                height: $spacing * 4;
                border: none !important;
                margin: 0;
                transform: none;
            }
        }

        &__back-button {
            position: relative;
            margin-right: $spacing * 4;

            &:before {
                content: url(~erpcore/assets/svg/arrowLeft.svg) !important;
            }
        }

        &__next-button {
            margin-left: $spacing * 4;

            &:before {
                content: url(~erpcore/assets/svg/arrowRight.svg) !important;
            }
        }

        // dots

        &__dot-group {
            display: flex;
            align-items: center;
            align-content: center;
        }

        &__dot {
            display: block;
            width: 18px;
            max-width: 18px;
            flex: 0 0 18px;
            height: 18px;
            margin: 0;
            padding: 5px;
            border: none;
            background: none;

            &:last-child {
                margin-right: 0;
            }

            &:not(:nth-child(3n + 1)) {
                display: none;
            }

            span {
                display: block;
                width: $spacing;
                height: $spacing;
                background: rgba(getColor(black), 1);
                transition: getTransition();
                border-radius: 50%;
            }

            &--selected,
            &:hover {
                span {
                    background: rgba(getColor(denim), 1);
                }
            }
        }
    }

    &__slide {
        $_slide: &;

        &-inner {
            padding-left: 5%;
            padding-right: 5%;
        }

        &-title {
            font-size: 20px;
        }

        &-content {
            ul {
                padding-left: 0;

                li {
                    position: relative;
                    display: block;
                    padding: 0 0 0 $spacing * 2;
                    margin: 0 0 $spacing * 3;
                    list-style-type: none;

                    &:before {
                        content: ' ';
                        position: absolute;
                        top: 5px;
                        left: 0;
                        display: block;
                        width: $spacing;
                        height: $spacing;
                        background: rgba(getColor(black), 1);
                    }
                }
            }
        }
    }
}
