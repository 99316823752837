@import 'erpcore/assets/scss/main';

.project-page-header {
    text-align: right;

    &__address {
        font: $font-label;
        margin: 0;
        text-transform: uppercase;
    }

    &__name {
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 $spacing / 2 0;
    }

    &__link {
        // display: inline-flex;
        display: inline-block;
        font-family: $font_family;
        font-size: 14px;
        font-weight: 400;
        // line-height: 40px;
        color: rgba(getColor(denim), 1);
        margin-left: $spacing * 2;

        &-label {
            display: inline-block;
            margin-left: $spacing;

            &:last-child {
                margin-right: 0;
            }
        }

        .svg {
            position: relative;
            top: -1px;
            display: inline-block;
            width: 13px;
            height: 13px;
            margin-left: $spacing;
            vertical-align: middle;

            svg {
                fill: rgba(getColor(denim), 1);
            }
        }

        &:hover {
            text-decoration: none;
        }
    }
}
