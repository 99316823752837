@import 'erpcore/assets/scss/main';

.purchase-calculator {
    padding: 0 $spacing * 4;
    width: 100%;
    text-align: center;

    &__logo {
        width: 200px;
    }

    &__item-list {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin: $spacing * 3 0;
    }

    &__item {
        display: flex;
        align-items: center;
        position: relative;
        border: 1px solid rgba(getColor(dark-grey), 1);
        flex: 0 1 calc(35% - 2%);
        margin: 1% 2%;
        margin-bottom: 4%;
        height: 350px;
        cursor: pointer;
        color: rgba(getColor(dark-grey), 1);

        &-content {
            text-align: left;
            padding: $spacing * 4;
        }
        &-name {
            font: $font-3;
            display: block;
            font-weight: lighter;
            text-transform: uppercase;
        }
        &-icon {
            width: $spacing * 6;
            fill: rgba(getColor(dark-grey), 1);
            margin-bottom: $spacing;
        }

        &-description {
            display: none;
            position: absolute;
            bottom: 0;
            left: 0;
            right: 0;
            width: 100%;
            list-style-type: none;
            padding: $spacing * 4;
            text-align: left;

            &-line {
                margin-bottom: $spacing;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }

        &:hover {
            background: rgba(getColor(blue), 1);
            border-color: rgba(getColor(blue), 1);
            color: rgba(getColor(white), 1);
            text-decoration: none;

            svg {
                fill: rgba(getColor(white), 1);
            }

            /*
            .demo-tour-overview__item-content {
                position: absolute;
                top: 0;
                left: 0;
            }

            .demo-tour-overview__item-description {
                display: block;
            }
            */
        }
    }
}
