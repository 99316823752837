@import 'erpcore/assets/scss/main';

%element-loader-colors {
    .dot1 {
        fill: rgba(getColor(white), 0.3);
    }

    .dot2 {
        fill: rgba(getColor(white), 0.5);
    }

    .dot3 {
        fill: rgba(getColor(white), 1);
    }
}

%element-loader-darkk-colors {
    .dot1 {
        fill: rgba(getColor(blue), 1);
    }

    .dot2 {
        fill: rgba(getColor(blue), 1);
        opacity: 0.7;
    }

    .dot3 {
        fill: rgba(getColor(blue), 1);
        opacity: 0.4;
    }
}

.button {
    $button: &;
    position: relative;
    display: inline-block;
    flex: 0 1 auto;
    align-items: center;
    max-width: 100%;
    height: 40px;
    margin: 0 ($spacing * 2) 0 0;
    padding: 12px ($spacing * 2);
    border: none;
    border-radius: 8px;
    font: $font-button;
    text-transform: none;
    text-align: center;
    text-decoration: none;
    color: rgba(getColor(white), 1);
    fill: rgba(getColor(white), 1);
    background-image: none;
    background-color: rgba(var(--buttonColor), 1);
    vertical-align: middle;
    user-select: none;
    touch-action: manipulation;
    cursor: pointer;
    white-space: nowrap;
    transition: getTransition();

    &:hover,
    &:active,
    &:focus {
        text-decoration: none;
        color: rgba(getColor(white), 1);
        opacity: 0.7;
    }

    &:disabled,
    &--disabled {
        background-color: rgba(getColor(bright-grey), 1);
        cursor: not-allowed;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: rgba(getColor(white), 1);
            background-color: rgba(getColor(bright-grey), 1);
            opacity: 1;
        }
    }

    &:last-child {
        margin-right: 0;
    }

    &__label {
        display: inline-block;
        margin-right: $spacing;

        &:last-child {
            margin-right: 0;
        }
    }

    &__icon {
        position: relative;
        top: -1px;
        display: inline-block;
        width: 20px;
        height: 20px;
        margin-right: $spacing;
        vertical-align: middle;
        fill: rgba(getColor(white), 1);

        &:last-child {
            margin-right: 0;
        }

        #{$button} .element-loader .svg,
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: rgba(getColor(white), 1);

            @extend %element-loader-colors;
        }

        &:last-child {
            margin-right: 0;
        }
    }

    .element-loader {
        margin: 0 auto;
        @extend %element-loader-colors;
    }

    &--secondary {
        color: rgba(getColor(grey), 1);
        background-color: transparent;

        &:before {
            content: '';
            position: absolute;
            display: block;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            border: 2px solid rgba(getColor(grey), 1);
            border-radius: 3px;
            z-index: 1;
            transition: getTransition();
        }

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: rgba(getColor(grey), 1);
            background-color: transparent;

            &:before {
                border: 2px solid rgba(getColor(blue), 1);
            }

            #{$button}__icon {
                fill: rgba(getColor(grey), 1);

                svg {
                    fill: rgba(getColor(grey), 1);
                }
            }
        }

        #{$button}__icon {
            fill: rgba(getColor(grey), 1);

            svg {
                fill: rgba(getColor(grey), 1);

                @extend %element-loader-darkk-colors;
            }
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }

        &:disabled,
        &#{$button}--disabled {
            color: rgba(getColor(white), 1);
            fill: rgba(getColor(white), 1);
            background-color: rgba(getColor(bright-grey), 1);
            cursor: not-allowed;

            &:before {
                border: 2px solid rgba(getColor(bright-grey), 1);
            }

            &:hover,
            &:active,
            &:focus {
                color: rgba(getColor(white), 1);
                fill: rgba(getColor(white), 1);
                background-color: rgba(getColor(bright-grey), 1);

                &:before {
                    border: 2px solid rgba(getColor(bright-grey), 1);
                }
            }

            #{$button}__icon {
                fill: rgba(getColor(white), 1);

                svg {
                    fill: rgba(getColor(white), 1);
                }
            }
        }
    }

    &--tertiary {
        color: rgba(getColor(blue), 1);
        background-color: transparent;

        &:hover,
        &:active,
        &:focus {
            text-decoration: none;
            color: rgba(getColor(blue), 0.7);
            background-color: transparent;

            #{$button}__icon {
                fill: rgba(getColor(blue), 0.7);

                svg {
                    fill: rgba(getColor(blue), 0.7);
                }
            }
        }

        #{$button}__icon {
            fill: rgba(getColor(blue), 1);

            svg {
                fill: rgba(getColor(blue), 1);

                @extend %element-loader-darkk-colors;
            }
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }

        &:disabled,
        &#{$button}--disabled {
            color: rgba(getColor(white), 1);
            fill: rgba(getColor(white), 1);
            background-color: getColor(blue-silver);
            cursor: not-allowed;

            &:hover,
            &:active,
            &:focus {
                color: rgba(getColor(white), 1);
                fill: rgba(getColor(white), 1);
                background-color: getColor(silver-grey);
            }

            #{$button}__icon {
                fill: rgba(getColor(white), 1);

                svg {
                    fill: rgba(getColor(white), 1);
                }
            }
        }
    }

    &--small {
        height: 28px;
        font: $font-list;
        padding: 5px $spacing;
        margin: 0 $spacing 0 0;

        &:before {
            border-width: 1px;
        }

        #{$button}__icon {
            width: 16px;
            height: 16px;
            vertical-align: middle;
        }

        #{$button}__icon,
        #{$button}__label {
            margin-right: $spacing/2;

            &:last-child {
                margin-right: 0px;
            }
        }

        &:hover {
            &:before {
                border-width: 1px;
            }
        }
    }

    &--large {
        height: 50px;
        padding: $spacing * 2 $spacing * 3;
        margin: 0 $spacing 0 0;
        font: $font-3;
        font-size: 18px;

        &:before {
            border-width: 1px;
        }
        &:hover {
            &:before {
                border-width: 1px;
            }
        }
    }

    &--loading {
        pointer-events: none;

        .element-loader {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            backface-visibility: hidden;
            transform-style: preserve-3d;
            margin: 0;
            z-index: 1;
            display: flex;
            align-items: center;
            justify-content: center;

            @extend %element-loader-colors;
        }

        #{$button}__icon,
        #{$button}__label {
            opacity: 0;
        }
    }

    &--link {
        @extend .button--tertiary;

        padding: 0;
        height: $spacing * 2;
        color: getColor(light-blue);

        &:hover,
        &:active,
        &:focus {
            color: getColor(light-blue-hover);

            #{$button}__icon {
                fill: getColor(light-blue-hover);

                svg {
                    fill: getColor(light-blue-hover);
                }
            }
        }

        #{$button}__icon {
            fill: getColor(light-blue);

            svg {
                fill: getColor(light-blue);

                @extend %element-loader-darkk-colors;
            }
        }

        @include breakpoint($to: tablet) {
            margin-top: -2px;
        }
    }

    .react-select {
        &__control {
            display: none !important;
        }

        &__option--is-focused {
            color: getColor(silver-grey);
            background-color: transparent;
        }

        &__option--is-selected {
            color: rgba(getColor(mid-grey), 1);
            background-color: rgba(getColor(bright-grey), 1);
        }
    }

    &--action {
        display: inline-block;
        width: $spacing * 6;
        margin: 0 0 0 $spacing;
        padding: 12px 14px;

        #{$button}__icon {
            display: block;
            top: 0;
            width: 16px;
            height: 16px;
            margin: 0 auto;
        }
    }

    &--table-action {
        display: flex;
        cursor: pointer;
        border-radius: 0;
        flex-wrap: nowrap;
        text-align: center;
        align-items: center;
        height: $spacing * 3;
        align-content: center;
        text-decoration: none;
        justify-content: center;
        border: none;
        background-color: transparent;
        padding: 0;
        -webkit-appearance: none;

        svg {
            transition: getTransition();
            fill: rgba(getColor(black), 1);
        }

        &:hover {
            svg {
                fill: rgba(getColor(blue), 1);
            }
        }

        .button__icon {
            top: 0;
            width: $spacing * 2;
            height: auto;
        }

        .element-loader {
            @extend %element-loader-darkk-colors;
        }
    }
}
