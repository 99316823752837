@import 'erpcore/assets/scss/main';
.gallery {
    $gallery: &;
    position: relative;
    width: 100%;

    img {
        max-width: initial;
    }

    &--fullscreen {
        position: absolute;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        min-height: 100%;

        > div {
            position: relative;
            height: 100%;
        }

        .carousel-slider {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
    }

    &--actual-fullscreen {
        position: fixed;
        width: 100%;
        height: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background: rgba(getColor(black), 1);
        z-index: 10000;
    }

    &__thumbnail-toggle {
        position: fixed;
        right: -1px;
        padding: $spacing;
        padding-right: $spacing * 5;
        top: $spacing * 23;
        margin: 0%;
        background: rgba(getColor(white), 1);
        z-index: 1000;
        cursor: pointer;
        border: 1px solid rgba(getColor(grey), 1);
        border-right: none;

        #{$gallery}--thumbs-active & {
            right: 237px;
            border: none;
        }

        .svg {
            width: $spacing * 3;
        }
    }

    &__zoom-toggle {
        position: fixed;
        right: -1px;
        padding: $spacing $spacing * 5 4px $spacing;
        top: $spacing * 29;
        margin: 0;
        background: rgba(getColor(white), 1);
        z-index: 998;
        cursor: pointer;
        border: 1px solid rgba(getColor(grey), 1);
        border-right: none;

        .svg {
            width: $spacing * 3;

            .svg {
                transition: getTransition();
                fill: rgba(getColor(black), 1);
            }
        }

        &--active {
            background: rgba(getColor(denim), 1);
            border: 1px solid rgba(getColor(denim), 1);

            .svg {
                fill: rgba(getColor(white), 1);
            }
        }
    }

    &__fullscreen-toggle {
        position: fixed;
        right: -1px;
        padding: $spacing $spacing * 5 4px $spacing;
        top: $spacing * 35;
        margin: 0;
        background: rgba(getColor(white), 1);
        z-index: 998;
        cursor: pointer;
        border: 1px solid rgba(getColor(grey), 1);
        border-right: none;

        .svg {
            width: $spacing * 3;

            .svg {
                transition: getTransition();
                fill: rgba(getColor(black), 1);
            }
        }
    }

    &__slide {
        display: flex;
        align-items: center;
        width: 100%;
        height: calc(100vh - 220px);
        min-height: 500px;

        #{$gallery}--fullscreen & {
            height: 100vh;
            min-height: auto;

            img {
                height: 100%;
            }
        }

        #{$gallery}--actual-fullscreen & {
            height: 100vh;
            min-height: 100vh;
        }

        &-img {
            display: block;
            width: auto !important;
            max-width: 100% !important;
            object-fit: contain;
            margin: 0 auto;

            #{$gallery}--fullscreen & {
                width: 100% !important;
                object-fit: cover;
            }
        }

        &-single {
            & > img.gallery__thumb-placeholder {
                display: none !important;
            }
        }
    }

    &__captions {

        &-slide {
            margin: $spacing * 2 0 0;
            font-weight: 600;
            letter-spacing: 3px;
            font-size: 14px;
            text-transform: uppercase;

            &:empty {
                margin: 0;
            }
        }
    }

    .magnifier {
        width: auto !important;
        max-width: 100%;
        height: auto;
        margin-left: auto;
        margin-right: auto;

        #{$gallery}--fullscreen & {
            width: 100% !important;
        }

        &-image {
            pointer-events: auto;
            // width: 100%;
            width: auto !important;
            max-width: 100%;
            margin: 0 auto;
            object-fit: contain;
        }

        .magnifying-glass {
            box-shadow: rgba(0, 0, 0, 0.3) 0px 0px 10px;
        }
    }

    .carousel {
        .thumbs-wrapper {
            position: fixed;
            right: -300px;
            top: $spacing * 23;
            margin: 0%;
            width: 317px;
            padding-top: $spacing * 6;
            background: rgba(getColor(white), 1);
            opacity: 0;
            pointer-events: none;
            transition: getTransition();
            z-index: 999;
            display: flex;
            max-height: calc(100vh - #{$spacing} * 23);
            overflow-y: auto;

            .control-arrow {
                display: none !important;
            }

            .thumbs {
                display: flex;
                flex-wrap: wrap;
                justify-content: flex-start;
                align-items: flex-start;
                transform: translate3d(0, 0, 0) !important;
                margin: 0;
                padding: 0 $spacing $spacing;
                white-space: normal;

                .thumb {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    overflow: hidden;
                    width: 142px;
                    height: 142px;
                    max-height: 142px;
                    border: none;
                    padding: $spacing;
                    margin: 0;
                    transition: getTransition();
                    cursor: pointer;

                    &.selected,
                    &:hover {
                        opacity: 0.5;
                    }

                    img {
                        display: block;
                        width: 100% !important;
                        height: 100%;
                        flex-grow: 0;
                        object-fit: cover;
                    }
                }
            }
        }
    }

    &--no-thumbs {
        .carousel {
            .thumbs-wrapper {
                display: none !important;
            }
        }
    }

    &--thumbs-active {
        .carousel {
            .thumbs-wrapper {
                right: 0;
                opacity: 1;
                pointer-events: auto;
            }
        }
    }
}
.carousel {
    width: 100%;
}
.carousel * {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
.carousel > img {
    width: 100%;
    pointer-events: none;
}
.carousel .thumbs-wrapper {
    margin: 20px;
    overflow: hidden;
}
.carousel .thumbs {
    -webkit-transition: all 0.15s ease-in;
    -moz-transition: all 0.15s ease-in;
    -ms-transition: all 0.15s ease-in;
    -o-transition: all 0.15s ease-in;
    transition: all 0.15s ease-in;
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
    position: relative;
    list-style: none;
    white-space: nowrap;
}
.carousel .thumb {
    -webkit-transition: border 0.15s ease-in;
    -moz-transition: border 0.15s ease-in;
    -ms-transition: border 0.15s ease-in;
    -o-transition: border 0.15s ease-in;
    transition: border 0.15s ease-in;
    display: inline-block;
    width: 80px;
    margin-right: 6px;
    white-space: nowrap;
    overflow: hidden;
    border: 3px solid #fff;
    padding: 2px;
}
.carousel .thumb:focus {
    border: 3px solid #ccc;
    outline: none;
}
.carousel .thumb.selected,
.carousel .thumb:hover {
    border: 3px solid #333;
}
.carousel .thumb img {
    vertical-align: top;
}
.carousel.carousel-slider {
    position: relative;
    margin: 0;
    overflow: visible !important;
}
.carousel .slider-wrapper {
    overflow: hidden;
    margin: auto;
    width: 100%;
    -webkit-transition: height 0.15s ease-in;
    -moz-transition: height 0.15s ease-in;
    -ms-transition: height 0.15s ease-in;
    -o-transition: height 0.15s ease-in;
    transition: height 0.15s ease-in;
}
.carousel .slider-wrapper.axis-horizontal .slider {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}
.carousel .slider-wrapper.axis-horizontal .slider .slide {
    flex-direction: column;
    flex-flow: column;
}
.carousel .slider-wrapper.axis-vertical {
    -ms-box-orient: horizontal;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -moz-flex;
    display: -webkit-flex;
    display: flex;
}
.carousel .slider-wrapper.axis-vertical .slider {
    -webkit-flex-direction: column;
    flex-direction: column;
}
.carousel .slider {
    margin: 0;
    padding: 0;
    position: relative;
    list-style: none;
    width: 100%;
}
.carousel .slider.animated {
    -webkit-transition: all 0.35s ease-in-out;
    -moz-transition: all 0.35s ease-in-out;
    -ms-transition: all 0.35s ease-in-out;
    -o-transition: all 0.35s ease-in-out;
    transition: all 0.35s ease-in-out;
}
.carousel .slide {
    min-width: 100%;
    margin: 0;
    position: relative;
    text-align: center;
    background: none !important;
}
.carousel .slide iframe {
    display: inline-block;
    width: calc(100% - 80px);
    margin: 0 40px 40px;
    border: 0;
}
.carousel .slide .legend {
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -ms-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    position: absolute;
    bottom: 40px;
    left: 50%;
    margin-left: -45%;
    width: 90%;
    border-radius: 10px;
    background: #000;
    color: #fff;
    padding: 10px;
    font-size: 12px;
    text-align: center;
    opacity: 0.25;
    -webkit-transition: opacity 0.35s ease-in-out;
    -moz-transition: opacity 0.35s ease-in-out;
    -ms-transition: opacity 0.35s ease-in-out;
    -o-transition: opacity 0.35s ease-in-out;
    transition: opacity 0.35s ease-in-out;
}
.carousel .control-dots {
    position: absolute;
    bottom: 0;
    margin: 10px 0;
    text-align: center;
    width: 100%;
}
@media (min-width: 960px) {
    .carousel .control-dots {
        bottom: 0;
    }
}
.carousel .control-dots .dot {
    -webkit-transition: opacity 0.25s ease-in;
    -moz-transition: opacity 0.25s ease-in;
    -ms-transition: opacity 0.25s ease-in;
    -o-transition: opacity 0.25s ease-in;
    transition: opacity 0.25s ease-in;
    opacity: 0.3;
    filter: alpha(opacity=30);
    box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.9);
    background: #fff;
    border-radius: 50%;
    width: 8px;
    height: 8px;
    cursor: pointer;
    display: inline-block;
    margin: 0 8px;
}
.carousel .control-dots .dot.selected,
.carousel .control-dots .dot:hover {
    opacity: 1;
    filter: alpha(opacity=100);
}
.carousel .carousel-status {
    position: absolute;
    top: 0;
    right: 0;
    padding: 5px;
    font-size: 10px;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.9);
    color: #fff;
}
.carousel:hover .slide .legend {
    opacity: 1;
}

// arrows
body {
    .carousel,
    .carousel.carousel-slider {
        .control-arrow {
            position: absolute;
            top: 0;
            bottom: 0;
            width: $spacing * 10;
            height: 100%;
            margin: 0;
            padding: $spacing * 3;
            background: rgba(#fff, 0.3);
            border: none !important;
            font-size: 32px;
            opacity: 0;
            cursor: pointer;
            transition: getTransition();
            z-index: 2;

            &:hover {
                background: rgba(#fff, 0.6);
            }

            &:before {
                display: block;
                width: $spacing * 4;
                height: $spacing * 4;
                border: none !important;
                margin: 0;
                transform: none;
            }
        }

        &:hover {
            .control-arrow {
                opacity: 1;
            }
        }
    }
    .carousel .control-prev.control-arrow {
        left: 0;

        &:before {
            content: url(~erpcore/assets/svg/arrowLeft.svg) !important;
        }
    }
    .carousel .control-next.control-arrow {
        right: 0;

        &:before {
            content: url(~erpcore/assets/svg/arrowRight.svg) !important;
        }
    }
}
