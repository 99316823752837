@import 'erpcore/assets/scss/main';

.main--project-home {
    padding: 0 !important;
}

.project-home {
    $_this: &;
    padding: 0;
    width: 100%;
    max-width: 100%;
    margin: 0;

    &__fullscreen {
        position: relative;
        display: block;
        width: 100%;
        height: 100vh;
        overflow: hidden;

        &-item {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            opacity: 0;
            pointer-events: none;
            transform: scale(0);
            transition: transform 0s, opacity 0.5s getTransitionEasing('ease-in-cubic');

            &[data-active='1'] {
                opacity: 1;
                pointer-events: auto;
                transform: scale(1);
                transition: transform 0s, opacity 0.5s getTransitionEasing('ease-out-cubic');
            }
        }
    }

    &__menu {
        position: absolute;
        top: $spacing * 4;
        left: 126px;
        display: flex;
        align-items: flex-start;
        pointer-events: none;

        > * {
            pointer-events: auto;
        }

        &-item {
            margin: 0 $spacing * 2 0 0;
            background: rgba(getColor(white), 1);
            color: rgba(getColor(dark-grey), 1);

            &:last-child {
                margin-right: 0;
            }

            &-button {
                border: none;
                border-radius: 0;
                width: 70px;
                height: 60px;
                padding: 10px 15px;
                color: rgba(getColor(black), 1);
                font-size: 12px;

                &:before {
                    border: none !important;
                }

                .button__label {
                    min-width: 130px;
                }

                .svg {
                    top: 0;
                    width: 40px;
                    height: 40px;

                    svg {
                        width: 40px;
                        height: 40px;
                        fill: rgba(getColor(black), 1);

                        #{$_this}__menu[data-active='1'] & {
                            opacity: 1;
                            padding: $spacing * 3;
                            height: auto;
                            pointer-events: auto;
                        }
                    }
                }
            }

            &[data-active='1'] #{$_this}__menu-item-button,
            #{$_this}__menu-item-button:hover,
            #{$_this}__menu-item-button:active,
            #{$_this}__menu-item-button:focus {
                background: rgba(getColor(denim), 1);
                color: #fff;

                svg {
                    fill: #fff;
                }
            }

            &--wide {
                #{$_this}__menu-item-button {
                    width: 100%;
                    margin: 0;
                    padding-left: $spacing * 3;
                    padding-right: $spacing * 3;
                    text-align: left;

                    &:active,
                    &:focus {
                        background: #fff;
                        color: rgba(getColor(black), 1);

                        svg {
                            fill: rgba(getColor(black), 1);
                        }
                    }

                    &:hover {
                        background: rgba(getColor(denim), 1);
                        color: #fff;

                        svg {
                            fill: #fff;
                        }
                    }

                    &:after {
                        content: '';
                        display: block;
                        position: absolute;
                        bottom: 0;
                        left: $spacing * 3;
                        right: $spacing * 3;
                        width: auto;
                        height: 1px;
                        border-bottom: 1px solid rgba(getColor(dark-grey), 1);
                        opacity: 1;
                        transition: getTransition();
                    }

                    &:hover {
                        &:after {
                            opacity: 0;
                        }
                    }

                    .svg {
                        top: -1px;
                        margin-right: $spacing * 2;
                        width: $spacing * 2;
                        height: $spacing * 2;

                        svg {
                            width: $spacing * 2;
                            height: $spacing * 2;
                        }
                    }
                }

                &[data-info-opened='0'] {
                    #{$_this}__menu-item-button {
                        &:after {
                            opacity: 0;
                        }
                    }
                }
            }
        }
    }

    &__info-box {
        display: block;
        width: 0;
        height: 0;
        padding: 0;
        max-height: calc(100vh - 124px);
        margin: 0;
        background: rgba(getColor(white), 1);
        color: rgba(getColor(dark-grey), 1);
        opacity: 0;
        overflow: hidden;
        pointer-events: none;
        transition: all 0.3s getTransitionEasing('ease-out-cubic');

        #{$_this}__menu-item[data-info-opened='1'] & {
            width: 450px;
            height: auto;
            padding: 0 $spacing * 3 0;
            opacity: 1;
            overflow: auto;
            pointer-events: auto;
            transition: width 0.1s, opacity 0.3s getTransitionEasing('ease-out-cubic') 0.15s,
                padding 0.3s getTransitionEasing('ease-out-cubic') 0.15s,
                height 0.3s getTransitionEasing('ease-out-cubic');

            #{$_this}__content,
            #{$_this}__attributes {
            }
        }
    }

    &__content {
        display: block;
        width: 100%;
        padding: $spacing * 3 0 0;
        margin: 0 0 $spacing * 4;
    }

    &__action {
        display: block;
        width: 100%;
        padding: 0;
        margin: 0;

        .page-content__row {
            margin: 0 0 $spacing * 4;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__attributes {
        display: block;
        width: 100%;
        padding: $spacing * 4 0 0;
        margin: 0 0 $spacing * 4;
        border-top: 1px solid rgba(getColor(dark-grey), 1);
    }

    &__name {
        display: block;
        font: $font-1;
        font-size: 45px;
        line-height: 50px;
        margin-bottom: $spacing * 4;
    }

    &__description {
        display: block;
        margin-bottom: $spacing * 4;
        font-size: 16px;
        line-height: 26px;
    }

    &__attribute-label {
        display: block;
        text-transform: uppercase;
        font: $font-label;
    }
    &__attribute-value {
        display: block;
        margin-bottom: $spacing * 2;
        font-weight: bold;
        font: $font-3;
        font-size: 18px;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__development-project-logo {
        width: 200px;
        margin-bottom: $spacing * 4;

        &:last-child {
            margin-bottom: 0;
        }

        img {
            display: block;
            width: 100%;
            height: auto;
        }
    }
}
