@import 'erpcore/assets/scss/main';

.projects {
    padding: $spacing * 4;
    width: 100%;
    text-align: center;
    max-width: 1500px;
    margin: 0px auto;

    &__logo {
        display: block;
        width: 140px;
        margin: 0px auto;
    }

    &__logo-wrapper {
        min-height: 70px;
    }

    &__developer-logo {
        width: 200px;
        margin: 0px auto;
    }

    &__subtitle {
        margin: $spacing * 6 0;
        font-weight: bold;
        line-height: 2;
    }

    &__item-list {
        position: relative;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        margin: $spacing * 3 0;
    }

    &__sign-in {
        max-width: 984px;
        margin: 0px auto;
        min-height: 270px;
        padding: 129px 260px;
        border: 1px solid rgba(getColor(dark-grey), 1);
        text-align: left;
    }

    &__item {
        $item: &;
        text-align: center;
        position: relative;
        border: 1px solid rgba(getColor(dark-grey), 1);
        color: rgba(getColor(dark-grey), 1);
        flex: 0 1 calc(30% - 1%);
        margin: 1%;
        margin-bottom: 4%;
        padding-bottom: $spacing * 4;
        cursor: pointer;
        transition: getTransition();

        @include breakpoint(mobile) {
            flex: 0 0 calc(50% - 16px);
            margin: $spacing $spacing $spacing * 3;
        }

        &:hover {
            text-decoration: none;
            color: rgba(getColor(dark-grey), 1);

            #{$item}-action {
                color: rgba(getColor(white), 1);
                background: rgba(getColor(dark-grey), 1);
            }

            .projects__item-image {
                // transform: scale(1.01);
            }
        }

        &-image {
            flex-basis: 100%;
            width: 100%;
            padding-top: 70%;
            background-color: rgba(getColor(light-grey), 1);
            background-size: cover;
            transition: getTransition();
        }
        &-content {
            flex-basis: 100%;
            width: 100%;
            padding: $spacing * 2;
        }
        &-location {
            display: block;
            text-transform: uppercase;
            // font: $font-list;
            font-size: 11px;
            line-height: 45px;
            color: #000000;
        }
        &-name {
            font-size: 40px;
            line-height: 45px;
            font-family: $font_family_butler;
            font-weight: 700;
            display: block;
            margin: 0;
            text-transform: uppercase;

            @include breakpoint(mobile) {
                font-size: 20px;
                line-height: 24px;
            }
        }
        &-type {
            display: block;
            text-transform: uppercase;
            font-size: 11px;
            line-height: 45px;
        }
        &-action {
            position: absolute;
            display: inline-block;
            text-transform: uppercase;
            padding: $spacing $spacing * 3;
            border: 1px solid rgba(getColor(dark-grey), 1);
            border-radius: 8px;
            margin: 0px auto;
            background: rgba(getColor(white), 1);
            bottom: -18px;
            left: 50%;
            transform: translateX(-50%);
            white-space: nowrap;
            font-size: 14px;
            transition: getTransition();
            letter-spacing: 0.8px;
        }
    }
}
