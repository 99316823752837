@import 'erpcore/assets/scss/main';

.page-content {
    $this: &;
    position: relative;
    width: 100%;
    display: block;
    padding: 0 0 ($spacing * 3) 0;

    &__section {
        position: relative;
        display: block;
        width: 100%;
        margin-bottom: $spacing * 4;

        &:last-child {
            margin-bottom: 0;
        }
    }

    &__column {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 100%;
        border: none;
        padding: $spacing * 8;
        margin: 0;

        &--no-padding {
            padding: 0;
        }
    }

    &__side-menu {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        width: 380px;
        // flex: 0 0 380px;
        border: none;
        padding: $spacing * 2;
        margin: 0;
        border-right: 1px solid rgba(getColor(light-grey), 1);
    }

    &__columns {
        display: flex;
        height: 100%;
        width: 100%;
        justify-content: space-between;
        margin-bottom: $spacing * 2;

        &:last-child {
            margin-bottom: 0;
        }

        #{$this}__column {
            margin-right: $spacing * 2;

            &:last-child {
                margin-right: 0;
            }
        }
    }

    &__row {
        display: flex;
        flex-wrap: wrap;
    }

    &__title {
        display: inline-block;
        width: 100%;
        font: $font-2;
        margin: ($spacing * 2) 0px ($spacing * 3) 0px;

        &:first-child {
            margin-top: 0px;
        }
    }

    &--dashboard {
        @include breakpoint($to: mobile) {
            .page-content__columns {
                flex-direction: column;
            }

            .page-content__column {
                width: 100%;
                flex-basis: 100%;
                margin-right: 0;
                margin-bottom: $spacing * 2;

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    &__helper-list {
        margin-bottom: $spacing * 3;
    }
}
